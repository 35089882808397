<template>
  <body style="position: relative;">
  <div id="wrap">
    <section>
        <headerLayout></headerLayout>
    </section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>기업인터넷</h2>
                    <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p>
                </div>
                <div class="location">
                    <ul>
                        <li><a >PRODUCTS</a></li>
                        <li><a >기업인터넷</a></li>
                        <li><span>서비스소개</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section svcInfo bgGray">
            <dl class="innWrap flex sLayout01">
                <dt>
                    <h3 class="sLtitle">서비스 소개</h3>
                    <p>
                        기업 전용 시설을 기반으로 KT 국사에서 고객 사업장까지 광케이블로<br />
                        연결해 인터넷과 더불어 고객 특성에 맞는 ICT Total Care 서비스를<br />
                        제공합니다.
                    </p>
                    <a @click="$router.push('/goSupCns000')" class="btn-md arrow btn-primary bdrds50">컨설팅 신청</a>
                </dt>
                <dd>
                    <div class="imgWrap">
                        <div class="img"></div>
                    </div>
                </dd>
            </dl>
        </section>

        <section class="sub-section whyKT bglightPurple">
            <h3 class="sCtitle">Why KT</h3>
            <div class="innWrap">
                <ul>
                    <li>
                        <h4>최고의 서비스 연속성 보장</h4>
                        <p>
                            대한민국의 ICT를 이끌어온 통신종가 KT가<br />
                            국내 유일 Full-Mesh형 전송망을 통해<br />
                            우회경로를 확보하여 무중단 서비스 제공하겠습니다.
                        </p>
                    </li>
                    <li>
                        <h4>업계 최다 통신국사</h4>
                        <p>
                            타사 대비 통신국사가 독보적으로 많은<br />
                            KT의 특장점을 기반으로 장애 시에도<br />
                            2배 이상 신속하게 처리하겠습니다.
                        </p>
                    </li>
                    <li>
                        <h4>국내 최다 레퍼런스 보유</h4>
                        <p>
                            안정적인 고객 통신망<br />
                            구축·운영 경험과 노하우를 통해<br />
                            탄탄한 네트워크를 제공하겠습니다.
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section class="sub-section svcStory">
            <h3 class="sCtitle">KT를 써보신 고객님들의 생생한 스토리를 통해 최적의 서비스를 제안해드립니다.</h3>
            <div class="innWrap svcTab flex">
                <div class="nav">
                    <ul>
                        <li class="tab01" @click="clickTab(1)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 1}"></span>
                                <a v-bind:class="{active: active === 1}">골프장</a>
                            </div>
                        </li>
                        <li class="tab02" @click="clickTab(2)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 2}"></span>
                                <a >공장</a>
                            </div>
                        </li>
                        <li class="tab03" @click="clickTab(3)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 3}"></span>
                                <a >교회</a>
                            </div>
                        </li>
                        <li class="tab04" @click="clickTab(4)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 4}"></span>
                                <a >DR</a>
                            </div>
                        </li>
                        <li class="tab05" @click="clickTab(5)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 5}"></span>
                                <a >전국형 기업</a>
                            </div>
                        </li>
                        <li class="tab06" @click="clickTab(6)">
                            <div>
                                <span class="line" v-bind:class="{active: active === 6}"></span>
                                <a >시설 관리 공단</a>
                            </div>
                        </li>
                        <li class="tab07" @click="clickTab(7)">
                            <div>
                                <span class="line"></span>
                                <a >대학교</a>
                            </div>
                        </li>
                        <li class="tab08" @click="clickTab(8)">
                            <div>
                                <span class="line"></span>
                                <a >병원</a>
                            </div>
                        </li>
                        <li class="tab09" @click="clickTab(9)">
                            <div>
                                <span class="line"></span>
                                <a >호텔</a>
                            </div>
                        </li>

                    </ul>
                </div>
                <div class="con">
                    <div id="tab01" v-show="active===1"><img src="@/assets/images/svc_01_02_01.jpg" alt=""></div>
                    <div id="tab02" v-show="active===2"><img src="@/assets/images/svc_01_02_02.jpg" alt=""></div>
                    <div id="tab03" v-show="active===3"><img src="@/assets/images/svc_01_02_03.jpg" alt=""></div>
                    <div id="tab04" v-show="active===4"><img src="@/assets/images/svc_01_02_04.jpg" alt=""></div>
                    <div id="tab05" v-show="active===5"><img src="@/assets/images/svc_01_02_05.jpg" alt=""></div>
                    <div id="tab06" v-show="active===6"><img src="@/assets/images/svc_01_02_06.jpg" alt=""></div>
                    <div id="tab07" v-show="active===7"><img src="@/assets/images/svc_01_02_07.jpg" alt=""></div>
                    <div id="tab08" v-show="active===8"><img src="@/assets/images/svc_01_02_08.jpg" alt=""></div>
                    <div id="tab09" v-show="active===9"><img src="@/assets/images/svc_01_02_09.jpg" alt=""></div>
                </div>
            </div><!--tab-->
        </section>
        <section class="sub-section svcKnowhow bgPurple">
            <h3 class="sCtitle">귀사의 규모에 맞게 최적의 네트워크를 설계하고 구축, 유지보수까지 KT만의 노하우를 가지고 제공해드립니다.</h3>
            <div class="innWrap">
                <ul>
                    <li>
                        <a @click="$router.push('/goSvcPod200')">
                            <img src="@/assets/images/logo_giga.png" alt="logo_giga">
                            <p>100인 미만 사업장</p>
                            <span class="more"></span>
                        </a>
                    </li>
                    <li>
                        <a @click="$router.push('/goSvcPod300')">
                            <img src="@/assets/images/logo_kornet.png" alt="logo_kornet">
                            <p>100인 이상 사업장</p>
                            <span class="more"></span>
                        </a>
                    </li>
                    <li>
                        <a @click="$router.push('/goSvcPod400')">
                            <img src="@/assets/images/logo_vpn.png" alt="logo_vpn">
                            <p>전국 본/지사 사업장</p>
                            <span class="more"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
        active: 1
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
   clickTab: function (tabNum) {
      this.active = tabNum
    }
  }
}
</script>